import { Directive, HostListener, Renderer2, ElementRef, Output, EventEmitter } from '@angular/core';
@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  @Output() scrolled = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 10) {
      this.renderer.addClass(this.el.nativeElement, 'scrolled');
      this.scrolled.emit(true);
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'scrolled');
      this.scrolled.emit(false);
    }
  }
}
