<!-- <header class="header sticky top-0 z-10 md:pt-7 md:pb-7 pt-4 pb-5" appScroll
    [ngClass]="{ 'header-box-shadow': isScrolled }" (scrolled)="isScrolled = $event">
    <div class="container flex flex-col sm:flex-row sm:items-center justify-between sm:gap-2">

        <a routerLink="/" class="flex-initial mb-3 sm:mb-0">
            <img src="assets/images/header_logo_image_3px.webp" alt="warehouse logo" class="h-14 sm:h-20"
                style="object-fit: contain;">
        </a>

        <div class="relative flex-initial w-full sm:w-auto">
            <input type="text"
                class="pl-12 pr-4 sm:py-3 py-2 input-box-border rounded-lg w-full md:w-96 text-black text-xs placeholder-black"
                placeholder="Search">
            <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <img src="assets/images/search_icon_3x.webp" alt="search icon" class="w-5 h-5"
                    style="object-fit: contain;">
            </div>
        </div>

    </div>
</header> -->

<header class="header sticky top-0 z-10 md:pt-7 md:pb-7 pt-4 pb-5" appScroll
    [ngClass]="{ 'header-box-shadow': isScrolled }" (scrolled)="isScrolled = $event">
    <div class="container flex flex-col sm:flex-row sm:items-center justify-between sm:gap-2">
        <a routerLink="/" class="flex-initial mb-3 sm:mb-0">
            <img src="assets/images/header_logo_image_3px.webp" alt="warehouse logo" class="h-14 sm:h-20"
                style="object-fit: contain;">
        </a>

        <div class="relative flex-initial w-full sm:w-auto">
            <app-search></app-search>
        </div>
    </div>
</header>