<!-- <input type="text"
    class="pl-12 pr-4 sm:py-3 py-2 input-box-border rounded-lg w-full md:w-96 text-black text-xs placeholder-black"
    placeholder="Search" [(ngModel)]="title" (input)="searchBlog()">
<div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
    <img src="assets/images/search_icon_3x.webp" alt="search icon" class="w-5 h-5" style="object-fit: contain;">
</div>
<ul *ngIf="blogs_Suggestions.length > 0" class="absolute bg-white border rounded-lg mt-2 w-full z-10">
    <li *ngFor="let suggestion of blogs_Suggestions" (click)="blogSuggestion(suggestion)"
        class="cursor-pointer p-2 hover:bg-gray-200">
        {{ suggestion.title }}
    </li>
</ul> -->
<!-- 
<form>
    <input
        class="pl-12 pr-4 sm:py-3 py-2 input-box-border rounded-lg w-full md:w-96 text-black text-xs placeholder-black"
        type="text" placeholder="Search" name="searchTitle" [(ngModel)]="title" [matAutocomplete]="auto"
        (input)="searchBlog()">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggestion of blogs_Suggestions" (click)="blogSuggestion(suggestion)">
            <span class="text-xs md:text-sm" style="color: black !important;"
                [innerHTML]="highlightText(suggestion.title, title)"></span>
        </mat-option>
        <mat-option *ngIf="errorMessage">
            <span class="text-xs md:text-sm" style="color: black !important;">{{ errorMessage }}</span>
        </mat-option>
    </mat-autocomplete>
    <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <img src="assets/images/search_icon_3x.webp" alt="search icon" class="w-5 h-5" style="object-fit: contain;">
    </div>
</form> -->

<form>
    <input
        class="pl-12 pr-4 sm:py-3 py-2 input-box-border rounded-lg w-full md:w-96 text-black text-xs placeholder-black"
        type="text" placeholder="Search" name="searchTitle" [(ngModel)]="title" [matAutocomplete]="auto"
        (input)="searchBlog()">
    <mat-autocomplete #auto="matAutocomplete">
        <ng-container *ngFor="let suggestion of blogs_Suggestions">
            <mat-option *ngIf="!suggestion.isTitle" (click)="blogSuggestion(suggestion)">
                <span class="text-xs md:text-sm" style="color: black !important;"
                    [innerHTML]="highlightText(suggestion.title, title)"></span>
            </mat-option>
            <mat-option *ngIf="suggestion.isTitle" style="pointer-events: none !important;">
                <span class="search-article-title font-semibold">{{ suggestion.title }}
                    <hr class="search-article-line"
                        *ngIf="suggestion.isTitle && suggestion.title === 'Related Articles'">
                </span>
            </mat-option>
        </ng-container>
        <mat-option *ngIf="errorMessage" style="pointer-events: none !important;">
            <span class="text-xs md:text-sm" style="color: black !important;">{{ errorMessage }}</span>
        </mat-option>
    </mat-autocomplete>
    <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <img src="assets/images/search_icon_3x.webp" alt="search icon" class="w-5 h-5" style="object-fit: contain;">
    </div>
</form>