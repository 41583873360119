import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogHomePageComponent } from './blog-home-page/blog-home-page.component';
import { BusinessCardComponent } from './blogs/business-card/business-card.component';
import { SocialMediaStrategiesComponent } from './blogs/social-media-strategies/social-media-strategies.component';
import { BusinessGrowthComponent } from './blogs/business-growth/business-growth.component';
import { DigitalMarketingComponent } from './blogs/digital-marketing/digital-marketing.component';
import { QualityPrintingServicesComponent } from './blogs/quality-printing-services/quality-printing-services.component';
import { BlogSearchPageComponent } from './blog-search-page/blog-search-page.component';

const routes: Routes = [
  { path: '', component: BlogHomePageComponent },
  { path: 'Business-Card', component: BusinessCardComponent },
  { path: 'Social-Media-Strategies', component: SocialMediaStrategiesComponent },
  { path: 'Business-Growth', component: BusinessGrowthComponent },
  { path: 'Digital-Marketing', component: DigitalMarketingComponent },
  { path: 'Quality-Printing-Services', component: QualityPrintingServicesComponent },
  { path: 'Blog-Search', component: BlogSearchPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
