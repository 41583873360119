import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-growth',
  templateUrl: './business-growth.component.html',
  styleUrl: './business-growth.component.css'
})
export class BusinessGrowthComponent {

  constructor(private router: Router) {
    this.blogURL = 'https://blog.printingwarehouses.com//Business-Growth'
  }

  blogURL = ''

  business_growth_contents = [
    {
      business_growth_points: `Brand Identity and Recognition : Custom printing allows businesses to create materials that reflect their unique brand identity. Whether it's through business cards, brochures, or packaging, custom printing ensures that every piece of material aligns with the brand's colors, logo, and messaging. This consistency helps in building brand recognition and recall among customers.`
    },
    {
      business_growth_points: `Professionalism and Credibility : High-quality custom printing conveys a sense of professionalism and attention to detail, which can enhance a business's credibility. When customers receive well-designed and professionally printed materials, they are more likely to perceive the business as trustworthy and reliable.`
    },
    {
      business_growth_points: `Personalization and Targeting : Custom printing enables businesses to personalize their marketing materials based on their target audience. This personalization can be in the form of customized messages, images, or offers that connect with specific customer segments. By targeting their marketing materials, businesses can increase their relevance and effectiveness.`
    },
    {
      business_growth_points: `Marketing Collateral : Custom printing services provide businesses with essential marketing collateral such as brochures, flyers, and posters. These materials serve as physical representations of the business and its offerings, making them valuable tools for marketing and promotion.`
    },
    {
      business_growth_points: `Product Packaging : The packaging of products plays a significant role in attracting customers and differentiating a brand from its competitors. Custom printing allows businesses to create unique and eye-catching packaging designs that enhance the perceived value of their products.`
    },
    {
      business_growth_points: `Cost Effectiveness : Despite the advancements in digital marketing, custom printing remains a cost-effective way to reach a targeted audience. With the right design and distribution strategy, businesses can achieve a high return on investment (ROI) from their custom printing efforts.`
    },
    {
      business_growth_points: `Event Managing : For businesses that participate in trade shows or events, custom printing services are invaluable. From banners and booth displays to promotional materials, custom printing helps businesses make a lasting impression at events, leading to increased brand awareness and potential customer acquisition.`
    },
    {
      business_growth_points: `Consistency Across Channels : Ensuring consistency across diverse marketing channels is crucial in today's marketing environment. Custom printing plays a vital role in maintaining a consistent visual identity across print materials, websites, social media, and other platforms. This consistency strengthens brand recall and messaging, strengthens the overall brand image.`
    },
  ]

  business_best_contents = [
    {
      business_points: `Business Card : Business Cards are essential for networking and leaving a lasting impression. Choose a design that reflects your brand identity and includes essential contact information. When you need reliable business card printing, ensure it reflects your brand identity and includes essential contact information.`
    },
    {
      business_points: `Brochures : Brochures are great for providing detailed information about your products or services. Consider the content and design to ensure it aligns with your branding and effectively communicates your message.`
    },
    {
      business_points: `Flyers : Best Flyer Designs should be ideal for promoting events, sales, or special offers. They should be eye-catching and concise, with a clear call to action.`
    },
    {
      business_points: `Posters and Banners : Effective for large-scale advertising and promotions. Consider where they will be displayed and choose designs that stand out in the planned environment.`
    },
    {
      business_points: `Promotional Products : Items like Can Coolers can serve as useful giveaways that keep your brand top of mind. Additionally, they offer practical value, ensuring your brand is associated with everyday convenience.`
    },
    {
      business_points: `Packaging : If you sell physical products, custom packaging can enhance the unboxing experience and strengthen your brand identity`
    },
  ]

  marketing_contents = [
    {
      marketing_points: `Know your Audience : Understanding your audience's demographics, interests, and behaviors helps you customize your materials to connect with them. Conducting market research or using customer data can provide valuable insights.`
    },
    {
      marketing_points: `Reflect your brand : Consistency in branding across all materials helps build brand recognition and trust. Ensure your logo, colors, and messaging align with your brand identity.`
    },
    {
      marketing_points: `Define your purpose : Clearly outline the goals of your materials. Are you aiming to inform, persuade, or educate? Each goal requires a different approach in terms of content and design.`
    },
    {
      marketing_points: `Craft your message : Your message should be clear, concise, and compelling. Focus on the benefits of your products or services and why they matter to your audience.`
    },
    {
      marketing_points: `Design for Impact : Visual appeal is crucial. Use high-quality images, well-chosen colors, and an organized layout to grab attention and convey your message effectively.`
    },
    {
      marketing_points: `Print Quality Matters : The quality of printing reflects on your brand. Choose a reputable printing service that can deliver high-quality results to make a positive impression.`
    },
    {
      marketing_points: `Consider Distribution : How and where your materials will be distributed impacts their format and content. Tailor them to fit the distribution channels you plan to use.`
    },
    {
      marketing_points: `Budget Wisely : Set a realistic budget that allows you to achieve your goals without overspending. Consider the cost of design, printing, and distribution when planning your budget.`
    },
  ]

  blogs = [
    {
      image_url: 'assets/images/article_section_image_01_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_02_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_03_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_04_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
  ]

  autoScrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
