<app-common-header></app-common-header>

<section>
    <div class="container">
        <div class="text-center lg:py-20 md:py-14 py-8">

            <h1 class="head-title text-3xl md:text-4xl font-semibold">
                Articles</h1>

            <p class="head-text mt-3 text-sm md:text-base font-normal">
                Drive Business Growth with Effective Strategies and Techniques
            </p>

        </div>

        <div class="grid lg:grid-cols-3 grid-cols-1 md:gap-y-8 lg:gap-y-5 gap-y-5 gap-x-5 mb-28">
            <a class="grid grid-rows-2" *ngFor="let blog of blogs" [routerLink]="blog.routerlink"
                (click)="autoScrollTop();">
                <div class="bg-cover bg-center rounded-tr-2xl rounded-tl-2xl"
                    [ngStyle]="{'background-image': 'url(' + blog.image_url + ')'}">
                </div>
                <div class="md:px-7 px-5 md:py-6 py-7 article-card rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">{{blog.title}}</p>
                    <!-- <p class="text-xs py-2">{{blog.date}}</p>
                    <p class="text-sm md:text-lg lg:text-sm font-normal lg:leading-normal leading-normal">
                        {{blog.description}}
                    </p> -->
                    <p class="blog-date-text py-1">{{blog.date}}</p>
                    <p class="blog-description-ellipse font-normal lg:leading-normal leading-normal">
                        {{blog.description}}
                    </p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Branding</button>
                    <!-- <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button> -->
                </div>
            </a>
        </div>

        <div class="hidden flex justify-center lg:mt-14 mt-8 pb-20 lg:pb-40">
            <button type="button" routerLink="/" (click)="autoScrollTop();"
                class="article-button rounded-lg text-white py-3 px-20 font-semibold text-base mt-2 md:mt-4">Load
                More</button>
        </div>

    </div>
</section>

<app-footer></app-footer>