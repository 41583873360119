import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-home-page',
  templateUrl: './blog-home-page.component.html',
  styleUrl: './blog-home-page.component.css'
})
export class BlogHomePageComponent {

  blogs = [
    {
      image_url: 'assets/images/article_section_image_01_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_02_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_03_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_04_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_05_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_06_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
  ]

  autoScrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
