import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrl: './digital-marketing.component.css'
})
export class DigitalMarketingComponent {

  constructor(private router: Router) {
    this.blogURL = 'https://blog.printingwarehouses.com//Digital-Marketing'
  }

  blogURL = ''

  digital_marketing_contents = [
    {
      digital_marketing_points: `Global Reach : Digital marketing allows businesses to exceed geographical boundaries and reach a global audience through various online channels such as social media, search engines, and websites.<br><br>
      This expansive reach enables businesses to connect with potential customers worldwide, opening up new market opportunities and increasing brand visibility on a global scale.`
    },
    {
      digital_marketing_points: `Cost-Effectiveness : Digital marketing is often more cost-effective than traditional marketing methods like print advertising or TV commercials. With digital channels, businesses can allocate their marketing budgets more efficiently, targeting specific audiences with lower costs per impression or click compared to traditional advertising methods.<br><br>
      This cost-effectiveness makes digital marketing an attractive option for businesses with limited marketing budgets or those looking to maximize their ROI.`
    },
    {
      digital_marketing_points: `Targeted Advertising : One of the key advantages of digital marketing is the ability to target specific audiences based on demographics, interests, behaviors, and other relevant criteria. This targeted approach ensures that marketing messages are delivered to the most relevant audience segments, increasing the likelihood of engagement and conversion.`
    },
    {
      digital_marketing_points: `Measurable Results : Digital marketing offers robust analytics and tracking tools that provide detailed insights into the performance of marketing campaigns. Businesses can track various metrics such as website traffic, conversions, click-through rates, and more in real-time, allowing them to measure the impact of their marketing efforts accurately.<br><br> 
      This data-driven approach enables businesses to make informed decisions about their marketing strategies, optimize campaigns for better results, and demonstrate the ROI of their digital marketing initiatives.`
    },
    {
      digital_marketing_points: `Personalization : Digital marketing enables personalized communication with customers, allowing businesses to tailor their marketing messages and content based on individual preferences, behaviors, and past interactions.<br><br>
      Personalization can be achieved through techniques such as dynamic content, email segmentation, and personalized recommendations, creating a more engaging and relevant experience for customers. By delivering personalized content to the right audience at the right time, businesses can drive higher engagement, loyalty, and conversions.`
    },
    {
      digital_marketing_points: `Brand Development : Digital marketing plays a crucial role in building and strengthening brand awareness, identity, and reputation in the digital space. Through consistent and strategic digital marketing efforts, businesses can establish their brand presence across various online channels, differentiate themselves from competitors, and create a strong brand image that resonates with their target audience.<br><br>
      Brand development in digital marketing involves crafting a unique brand voice, visual identity, and messaging that aligns with the brand's values, goals, and target audience, ultimately enhancing brand equity and recognition.`
    },
    {
      digital_marketing_points: `Flexibility : Digital marketing offers businesses a high degree of flexibility in terms of campaign execution, timing, and budget allocation. Unlike traditional marketing methods that require long lead times and fixed budgets, digital marketing campaigns can be launched quickly and adjusted in real-time based on performance data and market trends.`
    },
    {
      digital_marketing_points: `Improved Customer Insights : Digital marketing provides businesses with valuable customer data and insights that can be used to understand their target audience better, anticipate their needs, and personalize marketing strategies.<br><br>
      By leveraging data from various digital touchpoints such as website visits, social media interactions, email opens, and more, businesses can gain a deeper understanding of their customers' preferences, behaviors, and purchase patterns. These insights enable businesses to create more targeted and relevant marketing campaigns that resonate with their audience, leading to higher engagement and conversions.`
    },
    {
      digital_marketing_points: `Higher Engagement : Digital marketing channels offer higher engagement rates compared to traditional marketing methods. Platforms like social media, email, and mobile apps provide opportunities for businesses to engage with their audience through likes, comments, shares, and direct messaging, fostering two-way communication and building stronger relationships.<br><br>  
      By creating engaging and interactive content, businesses can capture the attention of their audience, encourage participation, and drive meaningful interactions that lead to brand loyalty and advocacy.`
    },
    {
      digital_marketing_points: `Enhanced Customer Relationships : Digital marketing allows businesses to build and nurture relationships with their customers through ongoing communication, engagement, and support. By  leveraging digital channels such as social media, email, and chat, businesses can stay connected with their audience, address their needs and concerns, and provide timely assistance.<br><br> 
      This continuous engagement helps businesses foster trust, loyalty, and long-term relationships with their customers, leading to repeat business, referrals, and positive word-of-mouth.`
    },
    {
      digital_marketing_points: `Improved Conversion Rates : Digital marketing strategies are designed to drive targeted traffic to businesses' websites or other digital assets, increasing the likelihood of conversions. Through techniques such as search engine optimization (SEO), pay-per-click (PPC) advertising, and conversion rate optimization (CRO), businesses can optimize their digital presence to attract qualified leads and convert them into customers.<br><br>  
      By delivering relevant and compelling content at every stage of the customer journey, businesses can guide prospects through the conversion funnel, increasing conversion rates and maximizing the return on their marketing investment.`
    },
    {
      digital_marketing_points: `Scalability : Digital marketing strategies can be easily scaled up or down to accommodate business growth, seasonal demand, or changing market conditions. Whether it's increasing ad spend during peak seasons, expanding into new markets, or launching new products or services, digital marketing offers the flexibility to adjust strategies and budgets accordingly.<br><br>   
      This scalability allows businesses to adapt their marketing efforts to evolving business needs and market dynamics, ensuring that they remain agile and competitive in a dynamic digital landscape.`
    },
  ]

  digital_marketing_objectives = [
    {
      title: `Search Engine Optimization (SEO)`,
      goal_point: `Goal : Improve organic search visibility and drive qualified traffic to the website`,
      strategy_point: `Strategy : Optimize website content and structure, target relevant keywords, earn quality backlinks, and improve user experience to increase search engine rankings and attract organic traffic.`,
    },
    {
      title: `Pay - Per - Click Advertising (PPC)`,
      goal_point: `Goal : Generate immediate traffic and conversions through targeted ads.`,
      strategy_point: `Strategy : Create targeted ad campaigns on platforms like Google Ads and Bing Ads, bid on relevant keywords, optimize ad copy and landing pages, and use audience targeting to reach specific demographics or interests.`,
    },
    {
      title: `Content Marketing`,
      goal_point: `Goal : Build brand awareness, educate the audience, and drive engagement`,
      strategy_point: `Strategy : Develop high-quality and relevant content such as blog posts, articles, videos, infographics, and eBooks, distribute content across various channels, and use storytelling to connect with the audience and convey messages.`,
    },
    {
      title: `Social Media Marketing`,
      goal_point: `Goal : Increase brand visibility, engage with the audience, and drive website traffic.`,
      strategy_point: `Strategy : Establish a strong presence on social media platforms like Facebook, Instagram, Twitter, and LinkedIn, create engaging content tailored to each platform, interact with followers.`,
    },
    {
      title: `Email Marketing`,
      goal_point: `Goal : Nurture leads, drive conversions, and build customer relationships.`,
      strategy_point: `Strategy : Sending personalized and targeted emails to subscribers, segmenting email lists based on user behavior or demographics, and automating email campaigns for efficiency.`,
    },
    {
      title: `Influencer Marketing`,
      goal_point: `Goal : Leverage influencers' reach and credibility to promote products/services`,
      strategy_point: `Strategy : Collaborating with influencers relevant to the brand's niche, having them create content or endorse products/services, and reaching their audience through sponsored posts or partnerships.`,
    },
    {
      title: `Affiliate Marketing`,
      goal_point: `Goal : Increase sales and leads through third-party promotion.`,
      strategy_point: `Strategy : Partnering with affiliates who promote products/services through unique links, paying them  a commission for each sale or lead generated, and tracking performance through affiliate marketing platforms.`,
    },
    {
      title: `Video Marketing`,
      goal_point: `Goal : Engage and educate audiences through video content.`,
      strategy_point: `Strategy : Creating and sharing videos on platforms like YouTube, social media, or the brand's website, using storytelling and visual content to convey messages effectively.`,
    },
    {
      title: `Mobile Marketing`,
      goal_point: `Goal : Reach audiences on mobile devices and drive mobile-specific actions.`,
      strategy_point: `Strategy : Use mobile ads and promotions to get people to do things on their phones, like downloading apps or signing up for services.`,
    },
  ]

  faq_contents = [
    {
      question: `What is Digital Marketing?`,
      answer: `Digital marketing is the use of online channels and strategies to promote products, services, or brands  to potential customers.`,
    },
    {
      question: `What are the advantages of Digital marketing?`,
      answer: `Digital marketing provides cost-effective outreach and precise targeting, allowing businesses to compete effectively and measure their results for improved ROI and growth.`,
    },
    {
      question: `Is Digital Marketing Applicable for Local Business?`,
      answer: `Yes, digital marketing is highly applicable and beneficial for local businesses as it allows them to target and engage with their local audience, increase their online visibility, and drive customers to their physical locations.`,
    },
    {
      question: `What is the difference between Traditional Marketing and Digital Marketing?`,
      answer: `Digital marketing is more cost-effective than traditional marketing because it offers precise targeting, measurable results, and the ability to optimize campaigns in realimagestime, reducing wasted ad spend and increasing ROI. Additionally, digital marketing often has lower entry costs and provides a global reach without the high costs associated with traditional media.`,
    },
    {
      question: `What are the five benefits of Digital Marketing`,
      answer: ``,
      benefits: [
        {
          benefit: `Targeted Advertising`,
        },
        {
          benefit: `Measurable Results`,
        },
        {
          benefit: `Global Reach`,
        },
        {
          benefit: `Cost Effectiveness`,
        },
        {
          benefit: `Engagement and Effectiveness`,
        },
      ]
    }
  ]

  blogs = [
    {
      image_url: 'assets/images/article_section_image_01_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_02_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_03_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_04_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
  ]

  autoScrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
