import { Component } from '@angular/core';
import { SearchService } from '../search.service';

@Component({
  selector: 'app-blog-search-page',
  templateUrl: './blog-search-page.component.html',
  styleUrl: './blog-search-page.component.css'
})
export class BlogSearchPageComponent {

  blogs: any[] = [];
  constructor(private searchService: SearchService) { this.blogs = this.searchService.getBlogs(); }

  autoScrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
