import { Component } from '@angular/core';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent {

  title: string = '';
  blogs_Suggestions: any[] = [];
  errorMessage: string = '';
  blogs: any[] = [];
  title_blogs: any[] = [];
  keyword_blogs: any[] = [];

  constructor(private searchService: SearchService, private router: Router) { }

  // searchBlog() {
  //   if (this.title) {
  //     this.blogs_Suggestions = this.searchService.searchBlogs(this.title);
  //     if (this.blogs_Suggestions.length === 0) {
  //       this.errorMessage = `Sorry, there are no blogs matching your query`;
  //     } else {
  //       this.errorMessage = '';
  //     }
  //   } else {
  //     this.blogs_Suggestions = [];
  //     this.errorMessage = '';
  //   }
  // }

  searchBlog() {
    if (this.title) {
      this.blogs = this.searchService.searchBlogs(this.title);

      this.title_blogs = this.blogs.filter(blog =>
        blog.title.toLowerCase().includes(this.title.toLowerCase())
      );

      this.keyword_blogs = this.blogs.filter(blog =>
        !blog.title.toLowerCase().includes(this.title.toLowerCase()) &&
        blog.keywords.some((keyword: string) => keyword.toLowerCase().includes(this.title.toLowerCase()))
      );

      if (this.keyword_blogs.length > 0) {
        this.blogs_Suggestions = [...this.title_blogs, { title: 'Related Articles', isTitle: true }, ...this.keyword_blogs];
      } else {
        this.blogs_Suggestions = this.title_blogs;
      }

      if (this.blogs_Suggestions.length === 0 || (this.blogs_Suggestions.length === 1 && this.blogs_Suggestions[0].isTitle)) {
        this.errorMessage = `Sorry, there are no blogs matching your query`;
        this.blogs_Suggestions = [];
      } else {
        this.errorMessage = '';
      }
    } else {
      this.blogs_Suggestions = [];
      this.errorMessage = '';
    }
  }


  blogSuggestion(suggestion: any) {
    this.title = suggestion.title;
    this.router.navigateByUrl((suggestion.routerlink))
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.blogs_Suggestions = [];
  }

  highlightText(text: string, term: string): string {
    if (!term) {
      return text;
    }
    const word = new RegExp(term, 'gi');
    return text.replace(word, match => `<span class="highlight-text">${match}</span>`);
  }


}
