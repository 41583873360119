import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quality-printing-services',
  templateUrl: './quality-printing-services.component.html',
  styleUrl: './quality-printing-services.component.css'
})
export class QualityPrintingServicesComponent {

  constructor(private router: Router) {
    this.blogURL = 'https://blog.printingwarehouses.com//Quality-Printing-Services'
  }

  blogURL = ''

  blogs = [
    {
      image_url: 'assets/images/article_section_image_01_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_02_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_03_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
    {
      image_url: 'assets/images/article_section_image_04_3x.webp',
      routerlink: '/',
      title: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      date: 'On 14th October 2023',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
    },
  ]

  autoScrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
