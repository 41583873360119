<footer class="footer-banner md:pt-14 md:pb-28 pb-10 pt-10">
    <div class="container flex md:items-center justify-between gap-4">
        <a routerLink="/">
            <img src="assets/images/header_logo_image_3px.webp" alt="warehouse logo" class="h-14 md:h-20"
                style="object-fit: contain;">
        </a>

        <div class="flex flex-col items-end justify-center gap-4">
            <div class="flex gap-2">
                <a href="https://www.facebook.com/printingwarehouses/" target="_blank">
                    <img src="assets/images/facebook_icon_3x.webp" alt="facebook logo" class="h-6"
                        width="24" height="20" style="object-fit: contain;"></a>
                <a href="https://www.linkedin.com/company/printingwarehouses/" target="_blank">
                    <img src="assets/images/linkedin_icon_3x.webp" alt="linkedin logo" class="h-6"
                        width="24" height="20" style="object-fit: contain;"></a>
                <a href="https://twitter.com/printingwa" target="_blank">
                    <img src="assets/images/twitter_icon_3x.webp" alt="twitter logo" class="h-6"
                        width="24" height="20" style="object-fit: contain;"></a>
            </div>
            <span class="hidden md:block lg:block text-xs font-normal">© 2024 All Rights Reserved,
                Printing
                Warehouses of America
            </span>
        </div>
    </div>

    <div class="container flex justify-center text-center">
        <span class="md:hidden text-xs text-center font-normal mt-5">© 2024 All Rights Reserved, Printing Warehouses of
            America
        </span>
    </div>

</footer>