<app-common-header></app-common-header>
<section>
    <div class="container">
        <div class="justify-center lg:w-8/12 mx-auto lg:mt-14 md:mt-10 mt-6">

            <h1 class="head-title text-2xl md:text-3xl font-bold lg:leading-snug md:leading-normal py-5 border-b">
                10 Must-Have Elements for an Effective Business Card</h1>

            <div class="flex justify-between w-full py-4 border-b items-center gap-2">
                <i class="header-date text-black font-normal">June 3 Monday</i>
                <!-- <button type="button" class="blog-button text-xs px-5 py-3 rounded-3xl">Technology</button> -->
                <button type="button" class="blog-button px-4 py-2 rounded-3xl">Technology</button>
            </div>

            <img src="assets/images/business_card_banner_image.webp" alt="section content image"
                class="w-full my-8 blog-image-shadow" style="object-fit: contain;">

            <p class="text-black text-xs md:text-sm font-normal lg:pb-2 pb-1">Picture this: You're at a dynamic
                networking
                event, meeting potential clients and partners. You've had a great conversation, and now it's time to
                exchange business cards. This small card could make all the difference. Will it stand out and make a
                memorable impact, or will it blend into a stack of forgettable designs? In today's fast-paced business
                world, a well-designed business card is more than just a way to share your contact information—it's a
                showcase of your brand. Let's explore the 10 must-have elements for an effective business card that will
                help you nail that first impression and stay memorable.
            </p>

            <div class="lg:py-2 py-1" *ngFor="let content of business_card_contents">
                <p class="head-title text-xl md:text-2xl font-bold lg:mt-2 mt-3">
                    {{content.title}}</p>

                <p class="mt-3 text-black text-xs md:text-sm font-normal">
                    <span [innerHTML]="content.description"></span>
                </p>
            </div>

            <!-- <div class="lg:py-2 py-1">
                <p class="head-title text-xl md:text-2xl font-bold lg:mt-2 mt-3">
                    Conclusion
                </p>
                <p class="mt-3 text-black text-xs md:text-sm font-normal">Looking to boost your business with
                    top-quality business card designs ? Choose <a href="https://printingwarehouses.com" target="_blank"
                        class="font-semibold">Printing Warehouses of America</a>, your trusted partner for premium
                    printing solutions. Our expert team specializes in creating unique, professional business cards that
                    make a memorable impact.<br><br>
                    We offer a wide range of customization options, including various materials, finishes, and designs
                    tailored to your brand identity. With our cutting-edge printing technology and commitment to
                    excellence, we ensure that your business cards stand out from the competition. Let Printing
                    Warehouses of America elevate your brand's image with exceptional business card designs, setting you
                    apart as a leader in your industry.
                </p>
            </div> -->

            <!-- <div class="lg:py-2 py-1">
                <ol class="list-decimal">
                    <li class="head-title text-xl md:text-2xl font-bold lg:mt-2 mt-3"
                        *ngFor="let content of business_card_contents">{{content.title}}
                        <p class="mt-3 text-black text-xs md:text-sm font-normal">
                            <span [innerHTML]="content.description"></span>
                        </p>
                    </li>
                </ol>
            </div> -->

            <div class="flex justify-end md:pt-16 md:pb-12 pt-7 pb-7 border-b gap-2">
                <div class="flex items-center justify-end gap-4 hidden">
                    <a routerLink="/">
                        <img src="assets/images/message_icon_3x.webp" alt="message icon" class="h-6" loading="lazy"
                            style="object-fit: contain;">
                    </a>
                    <p class="text-black text-xs md:text-xs font-normal">10</p>
                </div>

                <div class="flex items-center justify-end gap-2">

                    <p class="text-black text-xs md:text-xs font-normal mr-2 lg:mr-3">
                        Share
                    </p>

                    <button mat-fab shareButton="facebook" #facebookBtn="shareButton" [url]="blogURL" title=""
                        description="" image="">
                        <img src="assets/images/facebook_icon_3x.webp" alt="facebook icon" class="h-6" loading="lazy"
                            style="object-fit: contain;" width="24" height="20">
                    </button>

                    <button mat-fab shareButton="linkedin" #linkedinBtn="shareButton" [url]="blogURL" title=""
                        description="" image="">
                        <img src="assets/images/linkedin_icon_3x.webp" alt="linkedin icon" class="h-6" loading="lazy"
                            style="object-fit: contain;" width="24" height="20">
                    </button>

                    <button mat-fab shareButton="twitter" #twitterBtn="shareButton" [url]="blogURL" title=""
                        description="" image="">
                        <img src="assets/images/twitter_icon_3x.webp" alt="twitter icon" class="h-6" loading="lazy"
                            style="object-fit: contain;" width="24" height="20">
                    </button>

                    <button mat-fab shareButton="email" #emailBtn="shareButton" [url]="blogURL" title="" description=""
                        image="">
                        <img src="assets/images/email_icon_3x.webp" alt="email icon" class="h-6" loading="lazy"
                            style="object-fit: contain;" width="24" height="20">
                    </button>

                    <button mat-fab shareButton="copy" #copyBtn="shareButton" [url]="blogURL" title="" description=""
                        image="">
                        <img src="assets/images/copy_icon_3x.webp" alt="copy icon" class="h-6" loading="lazy"
                            style="object-fit: contain;" width="24" height="20">
                    </button>

                </div>
            </div>
            <div class="flex justify-between gap-3 md:py-12 py-6 mb-12">
                <a class="flex items-center justify-end gap-4 md:gap-6 w-full" routerLink="/Quality-Printing-Services"
                    (click)="autoScrollTop();">
                    <img src="assets/images/left_arrow_3x.webp" alt="left arrow"
                        class="h-6 md:h-8 md:w-4 cursor-pointer flex-shrink-0" loading="lazy"
                        style="object-fit: contain;">
                    <p
                        class="head-title text-xs md:font-semibold font-medium article-slider-text md:w-64 w-24 flex-grow">
                        Why High-Quality Printing Services Are Essential for Your Brand Image?
                    </p>
                </a>

                <a class="flex items-center justify-end gap-4 md:gap-6 w-full" routerLink="/Social-Media-Strategies"
                    (click)="autoScrollTop();">
                    <p
                        class="head-title text-xs text-left sm:text-right md:font-semibold font-medium article-slider-text md:w-64 w-24 flex-grow">
                        Expert Tips for Building Your Brand Through Social Media Strategies
                    </p>
                    <img src="assets/images/right_arrow_3x.webp" alt="right arrow"
                        class="h-6 md:h-8 md:w-4 cursor-pointer flex-shrink-0" loading="lazy"
                        style="object-fit: contain;">
                </a>
            </div>

            <!-- <div class="article-row md:gap-y-8 lg:gap-y-14 gap-y-5 py-10" style="display:none !important">
                <div class="lg:w-6/12 px-3" *ngFor="let article of articles">
                    <a [routerLink]="article.routerlink">
                        <div> <img [src]="article.image_url" alt="article image" loading="lazy"
                                class="w-full object-contain">
                        </div>
                        <div class="lg:pl-7 lg:pr-10 pr-7 pl-7 py-7 article-card rounded-bl-2xl rounded-br-2xl">
                            <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">
                                {{article.title}}</p>
                            <p class="text-xs py-3">
                                {{article.date}}</p>
                            <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                {{article.description}}</p>
                            <button type="button"
                                class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Technology</button>
                        </div>
                    </a>
                </div>
            </div> -->

            <div class="grid lg:grid-cols-2 grid-cols-1 md:gap-y-8 lg:gap-y-14 gap-y-5 gap-x-5 md:mb-28 mb-20"
                style="display:none !important">
                <a class="grid grid-rows-2" *ngFor="let blog of blogs" [routerLink]="blog.routerlink"
                    (click)="autoScrollTop();">
                    <div class="bg-cover bg-center rounded-tr-2xl rounded-tl-2xl"
                        [ngStyle]="{'background-image': 'url(' + blog.image_url + ')'}">
                    </div>
                    <div class="md:px-7 px-5 md:py-6 py-7 article-card rounded-bl-2xl rounded-br-2xl">
                        <p class="head-title font-bold md:text-xl lg:text-base">{{blog.title}}</p>
                        <p class="blog-date-text py-1">{{blog.date}}</p>
                        <p class="blog-description-text font-normal lg:leading-normal leading-normal">
                            {{blog.description}}
                        </p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>
            </div>

            <div class="flex justify-center md:mt-8 lg:pb-40 md:pb-32 pb-20 hidden">
                <button type="button" routerLink="/" (click)="autoScrollTop();"
                    class="article-button rounded-lg text-white py-3 px-14 font-semibold text-base">See More
                    Articles</button>
            </div>

        </div>
    </div>
</section>
<app-footer></app-footer>