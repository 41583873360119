<app-header></app-header>
<section class="banner mb-20">
    <div class="container lg:pb-28 md:pb-10 pb-16">
        <div class="text-center md:pt-10 md:pb-10 lg:pt-6 lg:pb-10 pt-2 max-w-4xl mx-auto">
            <h1 class="head-title text-2xl md:text-3xl lg:text-4xl font-semibold leading-8 lg:leading-snug">Explore
                Business Strategies and Tips from Printing Warehouses of America</h1>
            <p class="head-text md:mt-4 mt-4 text-sm md:text-base font-normal">
                Follow our Blog Journey for Creative Ideas
            </p>
        </div>
        <!-- <div class="md:py-6">
            <div class="flex lg:flex-wrap 2xl:flex-nowrap md:flex-wrap flex-wrap">
                <div class="2xl:w-4/12 lg:w-6/12 w-full lg:px-3">
                    <a routerLink="/Blog-Details">
                        <div class="bg-white lg:flex lg:flex-col h-full rounded-2xl">
                            <div class="h-56 md:h-96 lg:h-2/3 bg-cover bg-center rounded-tr-2xl rounded-tl-2xl"
                                style="background-image: url('/assets/images/banner_section_image_home_01_3px.webp');">
                            </div>
                            <div class="md:px-7 px-5 md:py-6 py-7">
                                <p class="head-title font-bold md:text-xl lg:text-base">Sed ut perspiciatis unde
                                    omnis iste natus error sit voluptatem accusantium</p>
                                <p class="text-xs lg:text-xs py-2">On 14th October 2023</p>
                                <p
                                    class="text-black text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...
                                </p>
                                <button type="button"
                                    class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="2xl:w-4/12 lg:w-6/12 w-full lg:px-3 pt-5 md:pt-5 lg:pt-0">
                    <a routerLink="/Social-Media-Strategies">
                        <div class="flex flex-col md:flex-row">
                            <div class="w-full md:w-3/12 lg:w-4/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                                style="background-image: url('/assets/images/social_media_thumbnail.webp')">
                            </div>
                            <div
                                class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                                <p class="head-title font-bold md:text-xl lg:text-base">Expert Tips for Building
                                    Your Brand Through Social Media Strategies</p>
                                <p class="text-xs lg:text-xs py-2">On 14th October
                                    2023</p>
                                <p
                                    class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                    In today's digital age, social media has become an essential tool for businesses and
                                    individuals...</p>
                                <button type="button"
                                    class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-4">Technology</button>
                            </div>
                        </div>
                    </a>
                    <a routerLink="/Business-Growth">
                        <div class="flex flex-col md:flex-row mt-5">
                            <div class="w-full md:w-3/12 lg:w-4/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                                style="background-image: url('/assets/images/business_growth_thumbnail.webp')">
                            </div>
                            <div
                                class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                                <p class="head-title font-bold md:text-xl lg:text-base">The Role of Custom
                                    Printing Services for Business Growth</p>
                                <p class="text-xs py-2">On 14th October
                                    2023</p>
                                <p
                                    class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                    In today's competitive business world, the role of custom printing services has
                                    become increasingly...
                                </p>
                                <button type="button"
                                    class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-4">Technology</button>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="2xl:w-4/12 lg:w-full w-full lg:mt-5 2xl:mt-0 lg:flex 2xl:block pt-5 md:pt-5 lg:pt-0">
                    <div class="lg:flex 2xl:block w-full lg:w-6/12 2xl:w-full lg:px-3">
                        <a routerLink="/Digital-Marketing" class="w-full">
                            <div class="flex flex-col md:flex-row w-full">
                                <div class="w-full md:w-3/12 lg:w-4/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                                    style="background-image: url('/assets/images/digital_marketing_thumbnail.webp')">
                                </div>
                                <div
                                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                                    <p class="head-title font-bold md:text-xl lg:text-base pb-1">The Benefits of
                                        Digital Marketing</p>
                                    <p class="text-xs py-2.5">On 14th October
                                        2023
                                    </p>
                                    <p
                                        class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                        Traditional marketing involves using established offline channels like print
                                        media, broadcast media, direct mail, outdoor advertising...
                                    </p>
                                    <button type="button"
                                        class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-4">Technology</button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="lg:flex 2xl:block w-full lg:w-6/12 2xl:w-full lg:px-3 mt-5 lg:mt-0 2xl:mt-5">
                        <a routerLink="/Quality-Printing-Services" class="w-full">
                            <div class="flex flex-col md:flex-row w-full">
                                <div class="w-full md:w-3/12 lg:w-4/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                                    style="background-image: url('/assets/images/quality_printing_thumbnail.webp')">
                                </div>
                                <div
                                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                                    <p class="head-title font-bold md:text-xl lg:text-base">Why High-Quality
                                        Printing Services Are Essential for Your Brand Image?</p>
                                    <p class="text-xs py-2">On 14th October 2023
                                    </p>
                                    <p
                                        class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                                        When it comes to building a successful brand, every detail matters. From the
                                        logo design to the color...</p>
                                    <button type="button"
                                        class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-4">Technology</button>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Banner starts -->
        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 grid-rows-2 py-8 gap-5">
            <div class="row-span-2">
                <a routerLink="/Business-Card" (click)="autoScrollTop();">
                    <div class="bg-white lg:flex lg:flex-col h-full rounded-2xl">
                        <div class="h-60 bg-cover bg-center rounded-tr-2xl rounded-tl-2xl"
                            style="background-image: url('/assets/images/business_card_banner_image.webp');">
                        </div>
                        <div class="md:px-7 px-5 md:py-6 py-7">
                            <p class="head-title font-bold md:text-xl lg:text-base">10 Must-Have Elements for an
                                Effective Business Card</p>
                            <p class="blog-date-text py-1">June 3 Monday</p>
                            <p class="blog-description-ellipse font-normal lg:leading-normal leading-normal">
                                You're at a dynamic
                                networking
                                event, meeting potential clients and partners. You've had a great conversation, and now
                                it's time to
                                exchange business cards.This small card could make all the difference. Will it stand out
                                and make a memorable impact, or will it blend into a stack of forgettable designs? In
                                today's fast-paced business world, a well-designed business card is more than just a way
                                to share your contact information—it's a showcase of your brand.
                            </p>
                            <button type="button"
                                class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Branding</button>
                            <!-- <button type="button"
                                class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button> -->
                        </div>
                    </div>
                </a>
            </div>

            <a routerLink="/Social-Media-Strategies" class="flex flex-col md:flex-row" (click)="autoScrollTop();">
                <div class="w-full md:w-3/12 lg:w-5/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                    style="background-image: url('/assets/images/social_media_thumbnail.webp')">
                </div>
                <div
                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">Expert Tips for Building
                        Your Brand Through Social Media Strategies</p>
                    <p class="blog-date-text py-1">April 2 Tuesday</p>
                    <p class="blog-description-text-overflow font-normal lg:leading-normal leading-normal">
                        In today's digital age, social media has become an essential tool for businesses and individuals
                        to build and improve their brand presence. With millions of active users on social media
                        platforms like Facebook Instagram, Twitter, LinkedIn, and more</p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Technology</button>
                    <!-- <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-4">Technology</button> -->
                </div>
            </a>

            <a routerLink="/Digital-Marketing" class="flex flex-col md:flex-row w-full" (click)="autoScrollTop();">
                <div class="w-full md:w-3/12 lg:w-5/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                    style="background-image: url('/assets/images/digital_marketing_thumbnail.webp')">
                </div>
                <div
                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">The Benefits of
                        Digital Marketing</p>
                    <p class="blog-date-text py-1">May 6 Monday</p>
                    <p class="blog-description-text-overflow font-normal lg:leading-normal leading-normal">
                        <!-- Traditional marketing involves using established offline channels like print media, broadcast
                        media, direct mail, outdoor advertising, and telemarketing to reach a wide audience. It
                        typically depends on one-way -->
                        In the modern digital age, developing a strong online presence is essential for businesses. With
                        the wide range of tools and strategies available in digital marketing, companies can expand
                        their reach engage with their audience
                    </p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Technology</button>
                </div>
            </a>

            <a routerLink="/Business-Growth" class="flex flex-col md:flex-row" (click)="autoScrollTop();">
                <div class="w-full md:w-3/12 lg:w-5/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                    style="background-image: url('/assets/images/business_growth_thumbnail.webp')">
                </div>
                <div
                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">The Role of Custom
                        Printing Services for Business Growth</p>
                    <p class="blog-date-text py-1">April 22 Monday</p>
                    <p class="blog-description-text-overflow font-normal lg:leading-normal leading-normal">
                        In today's competitive business world, the role of custom printing services has become
                        increasingly crucial for driving growth and staying ahead of the competition. Custom printing
                        offers businesses a range</p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Technology</button>
                </div>
            </a>

            <a routerLink="/Quality-Printing-Services" class="flex flex-col md:flex-row w-full"
                (click)="autoScrollTop();">
                <div class="w-full md:w-3/12 lg:w-5/12 h-48 md:h-auto flex bg-cover bg-center rounded-tl-2xl md:rounded-bl-2xl md:rounded-tr-none rounded-tr-2xl"
                    style="background-image: url('/assets/images/quality_printing_thumbnail.webp')">
                </div>
                <div
                    class="px-5 md:px-7 lg:px-5 pt-7 pb-7 md:pt-7 md:pb-5 h-auto bg-white w-full md:w-9/12 lg:w-8/12 md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">Why High-Quality
                        Printing Services Are Essential for Your Brand Image?</p>
                    <p class="blog-date-text py-1">May 20 Monday</p>
                    <p class="blog-description-text-overflow font-normal lg:leading-normal leading-normal">
                        When it comes to building a successful brand, every detail matters. From the logo design to the
                        color scheme, every aspect of your brand's image plays a crucial role in shaping how your target
                        audience perceives you</p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Technology</button>
                </div>
            </a>

        </div>
        <!-- /Banner ends -->
    </div>
</section>


<section>
    <div class="container">
        <div class="flex justify-center mb-24">
            <button type="button" routerLink="/Blog-Search" (click)="autoScrollTop();"
                class="article-button rounded-lg text-white py-3 px-14 font-semibold text-base mt-4 md:mt-2">See
                More Articles</button>
        </div>
    </div>
</section>


<section class="hidden">
    <div class="container pt-10">
        <div class="text-center md:py-10 py-2">
            <h1 class="head-title text-2xl md:text-4xl font-semibold">
                Popular Articles</h1>
            <p class="head-text mt-3 text-sm md:text-base font-normal">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            </p>
        </div>

        <!-- <div class="article-row md:gap-y-8 lg:gap-y-7 gap-y-5 mt-5 md:mt-0">
            <div class="lg:w-4/12 px-3" *ngFor="let article of articles">
                <a [routerLink]="article.routerlink">
                    <div>
                        <img [src]="article.image_url" alt="article image" loading="lazy" class="w-full object-contain">
                    </div>
                    <div class="lg:pl-7 lg:pr-10 pr-7 pl-7 py-7 article-card rounded-bl-2xl rounded-br-2xl">
                        <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">{{article.title}}</p>
                        <p class="text-xs py-3">{{article.date}}</p>
                        <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                            {{article.description}}</p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>
            </div>
        </div> -->

        <div class="grid lg:grid-cols-3 grid-cols-1 md:gap-y-8 lg:gap-y-5 gap-y-5 gap-x-5 mb-28">
            <a class="grid grid-rows-2" *ngFor="let blog of blogs" [routerLink]="blog.routerlink"
                (click)="autoScrollTop();">
                <div class="bg-cover bg-center rounded-tr-2xl rounded-tl-2xl"
                    [ngStyle]="{'background-image': 'url(' + blog.image_url + ')'}">
                </div>
                <div class="md:px-7 px-5 md:py-6 py-7 article-card rounded-bl-2xl rounded-br-2xl">
                    <p class="head-title font-bold md:text-xl lg:text-base">{{blog.title}}</p>
                    <p class="blog-date-text py-1">{{blog.date}}</p>
                    <p class="blog-description-text font-normal lg:leading-normal leading-normal">
                        {{blog.description}}
                    </p>
                    <button type="button"
                        class="blog-button duration-300 hover:bg-blue-100 px-4 py-2 rounded-3xl mt-3">Branding</button>
                </div>
            </a>
        </div>

        <div class="flex justify-center mt-8">
            <button type="button" routerLink="/Blog-Search" (click)="autoScrollTop();"
                class="article-button rounded-lg text-white py-3 px-14 font-semibold text-base mt-2 md:mt-4">See
                More Articles</button>
        </div>

    </div>
</section>

<section class="hidden">
    <div class="container lg:py-20 md:py-14 py-5">
        <div class="flex lg:flex-nowrap md:flex-wrap flex-wrap mt-5 lg:mt-4 md:mt-0">
            <div class="lg:w-4/12 md:px-2 lg:px-5">
                <a routerLink="/">
                    <div
                        class="lg:pr-6 md:pr-2 pr-4 pl-2 md:pl-2 lg:pl-0  md:pb-8 pb-5 pt-5 border-b border-line 2xl:mt-4">
                        <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">Sed ut perspiciatis
                            unde
                            omnis iste
                            natus error sit voluptatem accusantium</p>
                        <p class="text-xs py-2">On 14th October 2023
                        </p>
                        <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                            Lorem ipsum
                            dolor sit amet,
                            consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam... </p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>

                <a routerLink="/">
                    <div
                        class="lg:pr-6 md:pr-2 pr-4 pl-2 md:pl-2 lg:pl-0 md:pb-8 pb-5 pt-5 md:pt-8 2xl:mt-1 border-b lg:border-b-0 border-line">
                        <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">Sed ut perspiciatis
                            unde
                            omnis iste
                            natus error sit voluptatem accusantium</p>
                        <p class="text-xs py-2">On 14th October 2023
                        </p>
                        <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                            Lorem ipsum
                            dolor sit amet,
                            consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam... </p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>
            </div>

            <div class="hidden lg:block vertical-line"></div>

            <div class="lg:w-4/12 md:px-2 lg:px-5">
                <a routerLink="/">
                    <div class="lg:px-3 md:px-2 px-2 md:pb-8 pb-5 pt-5 border-b border-line 2xl:mt-4">
                        <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">Sed ut perspiciatis
                            unde
                            omnis iste
                            natus error sit voluptatem accusantium</p>
                        <p class="text-xs py-2">On 14th October 2023
                        </p>
                        <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                            Lorem ipsum
                            dolor sit amet,
                            consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam... </p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>

                <a routerLink="/">
                    <div class="lg:px-3 md:px-2 px-2 md:pb-8 pb-5 pt-5 md:pt-8 2xl:mt-1">
                        <p class="head-title font-bold md:text-xl lg:text-base 2xl:text-xl">Sed ut perspiciatis
                            unde
                            omnis iste
                            natus error sit voluptatem accusantium</p>
                        <p class="text-xs py-2">On 14th October 2023
                        </p>
                        <p class="text-xs md:text-lg lg:text-xs font-normal lg:leading-normal leading-normal">
                            Lorem ipsum
                            dolor sit amet,
                            consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam... </p>
                        <button type="button"
                            class="blog-button duration-300 hover:bg-blue-100 text-xs md:text-base lg:text-xs px-5 py-3 rounded-3xl mt-3">Branding</button>
                    </div>
                </a>
            </div>

            <div
                class="blog-design-background flex justify-between lg:flex-col md:flex-row flex-col lg:w-4/12 md:6/12 w-full my-8 lg:my-0">
                <div class="md:py-10 lg:py-14 py-12 md:px-7 px-5 self-center">
                    <h1
                        class="text-white text-3xl font-semibold text-center md:text-left lg:text-center py-18 leading-snug">
                        Start Designing
                        Your Project Now</h1>
                    <p class="text-center md:text-left lg:text-center text-sm text-white mt-3 lg:mt-3">
                        And unlock your full potential with our unmatched
                        printing services!
                    </p>
                </div>

                <div class="md:mt-8 lg:mt-0 mt-0 flex">
                    <img src="assets/images/blog_design_image_3x.webp" alt="blog design image" loading="lazy"
                        class="px-4 md:px-8 w-full 2xl:px-20 self-end" style="object-fit: contain;">
                </div>
            </div>

        </div>
    </div>
</section>
<app-footer></app-footer>