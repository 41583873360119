import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScrollDirective } from './scroll.directive';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { BlogHomePageComponent } from './blog-home-page/blog-home-page.component';
import { BusinessCardComponent } from './blogs/business-card/business-card.component';
import { BlogSearchPageComponent } from './blog-search-page/blog-search-page.component';
import { SocialMediaStrategiesComponent } from './blogs/social-media-strategies/social-media-strategies.component';
import { BusinessGrowthComponent } from './blogs/business-growth/business-growth.component';
import { DigitalMarketingComponent } from './blogs/digital-marketing/digital-marketing.component';
import { QualityPrintingServicesComponent } from './blogs/quality-printing-services/quality-printing-services.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareModule } from 'ngx-sharebuttons';
import { SearchComponent } from './search/search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    ScrollDirective,
    HeaderComponent,
    FooterComponent,
    CommonHeaderComponent,
    BlogHomePageComponent,
    BusinessCardComponent,
    BlogSearchPageComponent,
    SocialMediaStrategiesComponent,
    BusinessGrowthComponent,
    DigitalMarketingComponent,
    QualityPrintingServicesComponent,
    SearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ShareButtonModule,
    ShareIconsModule,
    ShareButtonsModule,
    ShareModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    BrowserAnimationsModule

  ],
  providers: [
    provideClientHydration(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
