import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }

  blogs = [
    {
      // Blog - 5

      image_url: 'assets/images/business_card_banner_image.webp',
      routerlink: '/Business-Card',
      title: '10 Must-Have Elements for an Effective Business Card',
      date: 'June 3 Monday',
      description: `You're at a dynamic networking event, meeting potential clients and partners. 
      You've had a great conversation, and now it's time to exchange business cards.This small card 
      could make all the difference. Will it stand out and make a memorable impact, or will it blend 
      into a stack of forgettable designs? In today's fast-paced business world, a well-designed 
      business card is more than just a way to share your contact information—it's a showcase of your brand.`,
      keywords: [
        `Professional business card`,
        `Best business card designs`,
        `Top business card designs`,
        `High-Quality printing Services`,
        `Best printing company in the USA`,
        `High quality Printing Services Florida`,
        `Quality printing Services Florida`,
      ]
    },
    {
      // Blog - 1

      image_url: 'assets/images/social_media_banner_image.webp',
      routerlink: '/Social-Media-Strategies',
      title: 'Expert Tips for Building Your Brand Through Social Media Strategies',
      date: 'April 2 Tuesday',
      description: `In today's digital age, social media has become an essential tool for businesses and 
      individuals to build and improve their brand presence. With millions of active users on social media 
      platforms like Facebook, Instagram, Twitter, LinkedIn, and more, leveraging social media effectively 
      can significantly impact brand visibility, engagement, and loyalty.By strategically utilizing these 
      platforms, businesses can reach their target audience, cultivate meaningful connections, and establish 
      themselves as industry leaders`,
      keywords: [
        `Social Media Management`,
        `Social Media Marketing`,
        `Best Social Media Marketing Services in the USA`,
        `Social Media Management Services`,
        `Social Media Agency Florida`,
        `Social Media Manager Florida`,
        `Social Media Marketing Florida`,
        `Social Media Marketing Services`,
        `Social Media Management Company`,
        `Top Social Media Management Services Florida`,
        `Best Social Media Management Services Florida`,
        `Affordable Social Media Management Florida`,
        `Social Media Management Services Orlando`,
      ]
    },
    {
      // Blog - 3

      image_url: 'assets/images/digital_marketing_banner_image.webp',
      routerlink: '/Digital-Marketing',
      title: 'The Benefits of Digital Marketing',
      date: 'May 6 Monday',
      description: `In the modern digital age, developing a strong online presence is essential for businesses. 
      With the wide range of tools and strategies available in digital marketing companies can expand their reach, 
      engage with their audience, and drive growth effectively. In this blog, we'll explore why digital marketing has 
      become so popular among businesses in different industries.We'll look at how it helps them connect with their 
      audience, boost brand awareness, and achieve growth in today's digital world`,
      keywords: [
        `Best digital marketing services in Florida`,
        `Best PPC management services in Florida`,
        `Best Social Media Management Services in Florida`,
        `Best Google ad services in Florida`,
        `Best marketing services in Orlando`,
        `Social media management services in Orlando`,
        `Affordable Digital Marketing Services Florida`,
        `Affordable SEO Services Florida`,
        `Affordable PPC Services Florida`,
        `Best digital marketing services in the USA`,
      ]
    },
    {
      // Blog - 2

      image_url: 'assets/images/business_growth_banner_image.webp',
      routerlink: '/Business-Growth',
      title: 'The Role of Custom Printing Services for Business Growth',
      date: 'April 22 Monday',
      description: `In today's competitive business world, the role of custom printing services has become increasingly 
      crucial for driving growth and staying ahead of the competition. Custom printing offers businesses a range of 
      benefits that can enhance their brand visibility, marketing efforts, and overall success. Let's delve into the 
      various ways in which custom printing services contribute to business growth.`,
      keywords: [
        `Custom printing services`,
        `High-quality custom printing`,
        `Business card printing`,
        `Best Flyer Designs`,
        `Custom printing in the USA`,
        `Custom printing services USA`,
        `Best Brochure Designs`,
        `Best Sign Designs`,
        `Affordable Custom Design Services Florida`,
        `Best Restaurant Menu Designs`,
        `Best Door Hanger Designs`,
        `Best Book Mark Designs`
      ]
    },
    {
      // Blog - 4

      image_url: 'assets/images/quality_printing_banner_image.webp',
      routerlink: '/Quality-Printing-Services',
      title: 'Why High-Quality Printing Services Are Essential for Your Brand Image?',
      date: 'May 20 Monday',
      description: `When it comes to building a successful brand, every detail matters. From the logo design to the color 
      scheme, every aspect of your brand's image plays a crucial role in shaping how your target audience perceives you. 
      One often overlooked element of branding is the quality of print materials used to represent your brand.In this digital 
      age, where online marketing dominates, the importance of high-quality printing services cannot be understated.`,
      keywords: [
        `Professional printing services`,
        `High-quality printing services`,
        `High-quality printing in USA`,
        `Affordable Printing Services in USA`,
        `Affordable Printing Services in Florida`,
        `Best printing services Florida`,
        `Top Printing Companies in USA`,
        `Printing Services in USA`,
      ]
    },
  ]

  getBlogs() {
    return this.blogs;
  }

  searchBlogs(data: string) {
    return this.blogs.filter(blogs =>
      blogs.title.toLowerCase().includes(data.toLowerCase()) ||
      blogs.keywords.some(keyword => keyword.toLowerCase().includes(data.toLowerCase()))
    );
  }
}
